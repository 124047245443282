.binary
{
	margin: 5px;
	padding: 5px;
	
	list-style-type: none;
	text-align: center;
}
.binary li { display: inline-block; }

.bit
{
	display: inline-block;
	
	width: 25px;
	height: 25px;
	
	margin: 8px;
	
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	transition: all 0.25s;
	opacity: 1;
}
.bit:active { opacity: 0.8; }
.bit:checked
{
	background: white;
	box-shadow: 0 0 8px 3px white;
}
.bit:not(:checked)
{
	border: 1px solid white;
	box-shadow: inset 0 0 8px white, 0 0 5px white;
}